const Devopcentrum = process.env.PUBLIC_URL + '/assets/Devopcentrum logo.svg';
function Footer() {
  return (
    <footer className=" bg-white text-black py-8 border-t border-gray-200">
        <div className="max-w-7xl mx-auto flex justify-between items-center">
        <div className="w-full">
            <div className="flex items-center justify-between">
                <div className="flex items-center">
                    <img src={Devopcentrum} alt="Devopscentrum"className="w-12 h-12" />
                    <h1 className="text-xl font-bold">DevOpsCentrum</h1>
                </div>
                <div className="flex space-x-8">
                    <div>
                        <a href="#" className="font-semibold">Solutions</a>
                    </div>
                    <div>
                        <a href="#" className="font-semibold">Resources</a>
                    </div>
                    <div>
                        <a href="#" className="font-semibold">Social</a>
                    </div>
                </div>
            </div>
            <div className="ml-2">
                <p>DevOps as a Service</p>
                <p>AI- Chatbot, ML Driven insights,</p>
                <p>End to End Infrastructure management</p>
                <p>Automated deployments.</p>
                <p className="mt-4">Contact Us: <a href="mailto:support@devopscentrum.com" className="text-blue-600 hover:underline">support@devopscentrum.com</a></p>
            </div>
        </div>
        </div>
        <div className="mt-8 border-t border-gray-200 pt-4"></div>
        <div className="max-w-7xl mx-auto flex justify-between items-center pt-4">
            <p>© DevOpSpatial Pvt Ltd  2024 | All rights Reserved</p>
            <div className="flex space-x-4">
                <a href="/privacy.html" className="hover:underline">Privacy Policy</a>
                <a href="/term.html" className="hover:underline">Terms of Service</a>
                <a href="/cookies.html" className="hover:underline">Cookies policy</a>
            </div>
        </div>
    </footer>
  )
}

export default Footer