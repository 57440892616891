import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Section from './Components/Home/Section';
import Price from './Components/Price/Price';
import Footer from './Components/Home/Footer'
import Navbar from './Components/Home/Navbar'
import GlobalStyle from './GlobalStyle';

function App() {
  return (
    <Router>
      <GlobalStyle />
      <Navbar />
      <Routes>
        <Route path='/' element={<Section />} />
        <Route path='/pricing' element={<Price />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;