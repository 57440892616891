import React, { useState } from 'react';
const arrow = process.env.PUBLIC_URL + '/assets/sort-down@2x.png';


const faqData = [
  {
    question: "What features does DevOpsCentrum offer?",
    answer: "DevOpsCentrum offers a wide range of features including automated deployments, continuous integration, monitoring, and more.",
  },
  {
    question: "How can I integrate DevOpsCentrum with my existing workflows?",
    answer: "You can integrate DevOpsCentrum using our comprehensive API and plugins for various tools.",
  },
  {
    question: "Is there a free trial available?",
    answer: "Yes, we offer a 7-day free trial with full access to all features.",
  },
  {
    question: "How do you ensure the security of my data?",
    answer: "We ensure data security through encryption, regular audits, and compliance with industry standards.",
  },
];

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="">
      <h2 className="font-bold text-center text-4xl mb-20">Frequently asked questions</h2>
      {faqData.map((item, index) => (
        <div key={index} className="mb-4">
          <button
            onClick={() => toggleAnswer(index)}
            className="w-full text-xl text-left py-2 px-12 duration-300 bg-white border-2 border-gray-200 rounded-t-md focus:outline-none focus:ring focus:bg-gray-200 focus:text-black"
          >
            {item.question}
            <span className="float-right">
              {activeIndex === index ? <img src={arrow} alt="arrow" className='h-6 w-6 transform rotate-180'/> : <img src={arrow} alt="arrow" className='h-6 w-6'/>}
            </span>
          </button>
          <div
            className={`overflow-hidden bg-gray-200 text-black rounded-b-md transition-max-height duration-700 ease-in-out ${activeIndex === index ? 'max-h-screen' : 'max-h-0'}`}
          >
            <p className="px-12 py-2 mt-3">{item.answer}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FAQ;
