import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

const Devopcentrum = process.env.PUBLIC_URL + '/assets/Devopcentrum logo.svg';
const arrow = process.env.PUBLIC_URL + '/assets/sort-down@2x.png';

function Navbar() {
  const [isVisible, setIsVisible] = useState(true);
  const [isProductDropdownOpen, setProductDropdownOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const dropdownRef = useRef(null);


  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setProductDropdownOpen(false);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setIsVisible(scrollPosition > currentScrollPos || currentScrollPos < 10);
      setScrollPosition(currentScrollPos);
    };
  
    window.addEventListener('scroll', handleScroll);
    document.addEventListener('mousedown', handleClickOutside);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [scrollPosition]);
  

  const toggleDropdown = () => {
    setProductDropdownOpen(prevState => !prevState);
  };

  return (
    <header
      className={`fixed font-sans top-0 left-0 right-0 z-20 bg-white p-1 flex justify-between items-center px-10 shadow-md transition-transform transform ${
        isVisible ? 'translate-y-0' : '-translate-y-full'
      }`}
    >
      <div className="flex items-center space-x-2">
        <Link to="/" className='flex items-center space-x-3'>
        <img src={Devopcentrum} alt="Brand Logo" className="h-14 w-14" />
        <p className="text-xl font-bold">DevOpsCentrum</p>
        </Link>
      </div>
      <nav className="flex items-center space-x-4 text-sm">
        <div className="flex items-center gap-16 text-md font-medium">
          <div
            className="relative"
            ref={dropdownRef}
          >
            <button
              className="hover:text-gray-700 flex justify-center items-center"
              onClick={toggleDropdown}
            >
              Product <span
                className={`ml-2  transform transition-transform ${
                  isProductDropdownOpen ? 'rotate-180' : 'rotate-0'
                }`}
              >
                <img src={arrow} alt="arrow" className='h-6 w-6'/>
              </span>
            </button>
            {isProductDropdownOpen && (
              <div className="fixed inset-0 mt-20 z-50">
                <div className="max-w-7xl mx-auto bg-white border border-gray-100 shadow-xl py-16 px-16">
                  <p className='font-semibold'>Effortless DevOps and infrastructure management for startups and SMEs, so you can focus on coding.</p>
                  <p className='text-xs font-medium mt-2'>DevOpsCentrum integrates ML and AI with traditional DevOps tools to streamline the process of software development.</p>
                  <div className="mt-8 mb-12 border-t border-gray-500 pt-4"></div>
                  <ul className="grid grid-cols-4 gap-8">
                    <li className="col-span-1">
                      <h2 className="font-semibold mb-4">AI Powered DevOps</h2>
                      <ul>
                        <li><Link to="/" className="block py-2 hover:text-[#4581e2] hover:rounded-md">Continuous Deployment</Link></li>
                        <li><Link to="#" className="block py-2 hover:text-[#4581e2] hover:rounded-md">Automated workflows</Link></li>
                        <li><Link to="#" className="block py-2 hover:text-[#4581e2] hover:rounded-md">Continuous integration</Link></li>
                        <li><Link to="#" className="block py-2 hover:text-[#4581e2] hover:rounded-md">Infrastructure as Code</Link></li>
                        <li><Link to="#" className="block py-2 hover:text-[#4581e2] hover:rounded-md">End-to-End CI/CD and Infra creation</Link></li>  
                      </ul>
                    </li>
                    <li className="col-span-1">
                      <h2 className="font-semibold mb-4">AI/ML</h2>
                      <ul>
                        <li><Link to="#" className="block py-2 hover:rounded-md hover:text-[#4581e2]">DevoSky Chatbot</Link></li>
                        <li><Link to="#" className="block py-2 hover:rounded-md hover:text-[#4581e2]">Intelligent Build</Link></li>
                        <li><Link to="#" className="block py-2 hover:rounded-md hover:text-[#4581e2]">Testing</Link></li>
                        <li><Link to="#" className="block py-2 hover:rounded-md hover:text-[#4581e2]">Monitoring</Link></li>
                      </ul>
                    </li>
                    <li className="col-span-1">
                      <h2 className="font-semibold mb-4">Monitoring/Alerting</h2>
                      <ul>
                        <li><Link to="#" className="block py-2 hover:rounded-md hover:text-[#4581e2]">Real-time insights</Link></li>
                        <li><Link to="#" className="block py-2 hover:rounded-md hover:text-[#4581e2]">Rich Graphical dashboards</Link></li>
                      </ul>
                    </li>
                    <li className="col-span-1">
                      <h2 className="font-semibold mb-4">Security</h2>
                      <ul>
                        <li><Link to="#" className="block py-2 hover:rounded-md hover:text-[#4581e2]">Infrastructure</Link></li>
                        <li><Link to="#" className="block py-2 hover:rounded-md hover:text-[#4581e2]">Application</Link></li>
                        <li><Link to="#" className="block py-2 hover:rounded-md hover:text-[#4581e2]">SAST & DAST</Link></li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>
          {/* <a href="customer.html" className='hover:underline'>Customer</a> */}
          <Link to="/pricing" className="hover:underline">Pricing</Link>
          <Link to="/blog" className="hover:underline">Blog</Link>
        </div>
      </nav>
      <div className='flex space-x-12 justify-center items-center text-sm'>
        <Link to="https://app.devopscentrum.com/signin" className="hover:underline text-blue-700">Sign In</Link>
        <Link to="https://app.devopscentrum.com/signup" className="bg-gray-800 text-white px-8 py-3 rounded-2xl hover:bg-blue-700">Sign Up for free</Link>
      </div>
    </header>
  );
}

export default Navbar;
