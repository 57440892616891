import React from 'react';
import FAQ from './FAQ';
import ReactPlayer from 'react-player';

const assetsPath = process.env.PUBLIC_URL + '/assets/';

const images = {
  AICHATBOT: 'artificial-intelligence-chatbot-roboadvisor@2x.png',
  DevOps: 'devops-software-development-methodology-and-framework@2x.png',
  github: '2993771-github-social-media-icon-1@2x.png',
  git: '2993773-git-social-media-icon-1@2x.png',
  gitlab: '4373151-gitlab-logo-logos-icon-1@2x.png',
  bitbucket: '4373267-bitbucket-logo-logos-icon-1@2x.png',
  amazon: 'amazon-web-services@2x.png',
  gocd: 'gocd1024x513-1@2x.png',
  google: 'googlecloudplatformlogo-1@2x.png',
  SettingAPI: '7461489-1@2x.png',
  Internet: 'pngegg-1@2x.png',
  MachineAI: 'handdrawnaialignmentillustration-1@2x.png',
  HumanCalender: '19198862-1@2x.png',
  DevOpscentrum: 'DevOpscentrum promo.mp4',
  DevoSky: 'gif-devosky-devopscentrum.gif',
  DevOpscentrumGroupIcon: 'gif-icons devopscentrum.gif',
  DevOpsHeroImage: 'DevOps hero image.svg',
  arrow: 'sort-down@2x.png'
};

function Section() {
  return (
    <div className="font-sans antialiased text-gray-900 flex flex-col min-h-screen">
      <main>
        <section className="py-20" aria-labelledby="section-intro">
          <div className="max-w-7xl mx-auto px-2 flex justify-between items-center h-screen">
            <div className="flex flex-col gap-12">
              <div className='w-96'>
                <h1 id="section-intro" className="text-[24px] font-semibold">Focus on code, we’ll handle the rest</h1>
                <p className="text-4xl font-semibold mt-4">
                  Your All-in-one <br /> <span className="font-semibold text-[#246BDB]">AI-Powered</span> <br/> DevOps &  <br/>Infrastructure <br/> Platform
                </p>
              </div>
              <div className='w-[600px]'>
                <p className='text-sm font-[500]'>
                  An end-to-end DevOps and infrastructure management platform tailored for startups and SME businesses, enabling them to focus
                  solely on coding without the concerns of managing infrastructure, development, and production support for their products.
                </p>
              </div>
              <div>
                <a href="/signup" className="bg-gray-800 text-white px-10 py-4 rounded-2xl hover:bg-blue-700">Sign Up for free</a>
              </div>
            </div>
            <img src={`${assetsPath}${images.DevOpsHeroImage}`} alt="DevOps Hero Image" className='h-[468px]' />
          </div>
        </section>
        <section className="bg-white py-20" aria-labelledby="section-about">
          <div className="max-w-7xl mx-auto px-2">
            <div 
              className='w-full h-[648px] mb-40  relative group' 
              aria-label="DevOpsCentrum Promo Video">
              <ReactPlayer
                url="https://www.youtube.com/watch?v=NSt7uocs0Sw"
                playing={true}
                controls={true}
                width="100%"
                height="100%"
              />
            </div>
            <div className="mb-[197px]">
              <h2 id="section-about" className="text-[40px] font-semibold">We Revolutionize SDLC to enhance <br /> and streamline software development</h2>
              <p className="text-sm mt-9 font-semibold">
                Our mission is to integrate AI-driven tools with traditional DevOps solutions, providing a comprehensive,
                end-to-end service that accelerates speed-to-market for our clients.
              </p>
            </div>
            <section aria-labelledby="section-features">
              <h2 id="section-features" className="text-[54px] font-bold text-center">How Do we Do it?</h2>
              <div className="mb-[500px]">
                <h3 className="text-4xl mt-40 font-semibold">DevoSKy AI</h3>
                <div className='flex justify-between items-center mt-16 w-full gap-11'>
                  <div className='w-[400px] h-[400px]'>
                    <img src={`${assetsPath}${images.DevoSky}`} alt="DevoSky AI" className='border-2 shadow-sm rounded-3xl'/>
                  </div>
                  <p className="w-[600px] mt-4 font-[500]">
                    DevoSky AI offers a robust solution designed to enhance your DevOps workflow. Our AI-powered chatbot
                    provides comprehensive assistance, from setting up repositories to resolving complex queries.
                  </p>
                </div>
              </div>
              <div className="flex flex-wrap">
                <article className='flex justify-between items-center w-full mb-56'>
                  <div>
                    <h3 className="text-4xl font-bold">UNIFIED HUB</h3>
                    <p className="mt-12 w-[500px] font-[500]">
                      Our platform seamlessly integrates a variety of AI-driven tools with traditional DevOps solutions,
                      offering a comprehensive and unified interface.
                    </p>
                  </div>
                  <div className='w-[400px] h-[400px]'>
                    <img src={`${assetsPath}${images.DevOpscentrumGroupIcon}`} alt="DevOpscentrum Group Icon" />
                  </div>
                </article>
                <article className="flex justify-between items-center w-full mb-56">
                  <div className='flex flex-col gap-12'>
                    <h3 className="text-4xl font-bold">Automated Deployments</h3>
                    <p className="w-[500px] font-[500]">
                      Our DevOps tool features a cutting-edge automated deployment system designed to streamline and simplify
                      your deployment processes.
                    </p>
                  </div>
                  <div className='w-[400px] h-[400px]'>
                    <img src={`${assetsPath}${images.SettingAPI}`} alt="Setting API" />
                  </div>
                </article>
                <article className="flex justify-between items-center w-full mb-56">
                  <div className='w-[400px] h-[400px]'>
                    <img src={`${assetsPath}${images.Internet}`} alt="Internet" />
                  </div>
                  <div className='flex flex-col gap-12'>
                    <h3 className="text-4xl font-bold">End to End infrastructure <br /> management</h3>
                    <p className="w-[500px] font-[500]">
                      Experience seamless project execution with our integrated end-to-end infrastructure management solution, 
                      complemented by advanced automated deployment capabilities. From initial setup to ongoing maintenance, our platform ensures 
                      your focus remains on coding and innovation, while we handle the complexities of deployment and infrastructure
                      upkeep. Optimize efficiency, minimize downtime, and accelerate your development cycles with 
                      our comprehensive DevOps toolset.
                    </p>
                  </div>
                </article>
                <article className="flex justify-between items-center w-full mb-56">
                  <div className='flex flex-col gap-12'>
                    <h3 className="text-4xl font-bold">Automated end to end code quality <br /> checks</h3>
                    <p className="w-[500px] font-[500]">
                      At DevOpsCentrum, we prioritize code quality with robust tools that ensure thorough checks throughout the development lifecycle.
                      Our platform automates and enhances code reviews, identifies potential issues early on, and supports teams
                      in maintaining high standards of software integrity and reliability.
                    </p>
                  </div>
                  <div className='w-[400px] h-[400px]'>
                    <img src={`${assetsPath}${images.HumanCalender}`} alt="Human Calendar" />
                  </div>
                </article>
                <article className="flex justify-between items-center w-full mb-56">
                  <div className='w-[400px] h-[400px]'>
                    <img src={`${assetsPath}${images.MachineAI}`} alt="Machine AI" />
                  </div>
                  <div className='flex flex-col gap-12'>
                    <h3 className="text-4xl font-bold">ML Driven Insights</h3>
                    <p className="w-[500px] font-[500]">
                      GoCD ML-driven insights to proactively identify and resolve operational issues,
                      leveraging advanced analytics and real-time data analysis. Our solution predicts
                      potential disruptions and optimizes performance, ensuring reliability and continuous improvement across your Infrastructure.
                    </p>
                  </div>
                </article>
              </div>
            </section>
            <div className="mt-12">
              <FAQ />
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default Section;