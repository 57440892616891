import React, { useState } from 'react'
import FAQ from '../Home/FAQ';

function Price() {
const [billingCycle, setBillingCycle] = useState('monthly');
const price = (plan) => {
    const monthlyPrices = {
      Silver: '$199',
      Gold: '$499',
      Platinum: '$999',
    };

    const yearlyPrices = {
      Silver: '$180',
      Gold: '$450',
      Platinum: '$899',
    };

    return billingCycle === 'monthly' ? monthlyPrices[plan] : yearlyPrices[plan];
  };
      
const handleToggle = () => {
        setBillingCycle(billingCycle === 'monthly' ? 'yearly' : 'monthly');
};
  return (
    <div className="p-8 py-24 ">
    <div className="max-w-7xl mx-auto">
      <div className="mb-0 flex flex-col gap-0">
        <h1 className="text-[36px] font-bold  mb-1">Choose a Pricing Plan</h1>
        <div className="flex mb-4">
          <div className="flex border rounded-full overflow-hidden">
            <button
              className={`px-12 py-2 rounded-full duration-300 ${billingCycle === 'monthly' ? 'bg-blue-500 text-white' : 'bg-white text-black'}`}
              onClick={handleToggle}
            >
              Monthly
            </button>
            <button
              className={`px-12 py-2 rounded-full duration-300 ${billingCycle === 'yearly' ? 'bg-blue-500 text-white' : 'bg-white text-black'}`}
              onClick={handleToggle}
            >
              Yearly
            </button>
          </div>
        </div>
      </div>
      <div className="grid items-end grid-cols-1 md:grid-cols-3 gap-8 mb-28">
      <div className='p-6 py-10 h-[689px] flex flex-col justify-evenly border shadow-lg rounded-lg bg-[#F6f8fa]'>
        <h2 className="text-4xl font-semibold mb-4">Silver</h2>
        <hr/>
        <p className="text-[44px] font-semibold mb-4">{price('Silver')}</p>
        <p className='mb-2'>Per month/User</p>
        <button className="px-4 py-2 bg-black text-white w-full rounded">Start Your Subscription</button>
        <div className='mt-10 grid grid-cols-8 gap-4'>
          <div className=''>✓</div>
          <div className='col-span-7'>Shared Infra (Kubernetes Cluster + Worker nodes)</div>
          <div className=''>✓</div>
          <div className='col-span-7'>Shared VPC</div>
          <div className=''>✓</div>
          <div className='col-span-7'>Email Support</div>
          <div className='text-gray-500'>✗</div>
          <div className='col-span-7 text-gray-500'>Dedicated Infra</div>
          <div className='text-gray-500'>✗</div>
          <div className='col-span-7 text-gray-500'>Dedicated Worker nodes</div>
        </div>
      </div>
      <div className='p-6 py-10 h-[739px] flex flex-col justify-evenly shadow-lg border rounded-lg bg-[#92aa00]'>
        <h2 className="text-4xl font-semibold mb-4">Gold</h2>
        <hr/>
        <p className="text-[44px] font-semibold mb-4">{price('Gold')}</p>
        <p className='mb-2'>Per month/User</p>
        <button className="px-4 py-2 bg-black w-full text-white rounded">Start Your Subscription</button>
        <div className='mt-10 grid grid-cols-8 gap-4'>
          <div className=''>✓</div>
          <div className='col-span-7'>Shared Infra (Kubernetes Cluster)</div>
          <div className=''>✓</div>
          <div className='col-span-7'>Dedicated VPC</div>
          <div className=''>✓</div>
          <div className='col-span-7'>Chat & Email Support</div>
          <div className=''>✓</div>
          <div className='col-span-7'>Dedicated Worker nodes</div>
          <div className='text-gray-500'>✗</div>
          <div className='col-span-7 text-gray-500'>Dedicated Infra</div>
          <div className='text-gray-500'>✗</div>
          <div className='col-span-7 text-gray-500'>Phone Support(24*7)</div>
        </div>
      </div>
      <div className='p-6 border py-10 h-[787px] flex flex-col justify-evenly shadow-lg rounded-lg bg-[#F6F8FA]'>
        <h2 className="text-4xl font-semibold mb-4">Platinum</h2>
        <hr/>
        <p className="text-[44px] font-semibold mb-4">{price('Platinum')}</p>
        <p className='mb-2'>Per month/User</p>
        {/* <p className='mb-2'>Per {billingCycle === 'monthly' ? 'month' : 'year'}/Member</p> */}
        <button className="px-4 py-2 w-full bg-black text-white rounded">Start Your Subscription</button>
        <div className='mt-10 grid grid-cols-8 gap-4'>
          <div className=''>✓</div>
          <div className='col-span-7'>Dedicated Infra</div>
          <div className=''>✓</div>
          <div className='col-span-7'>Dedicated VPC/Account</div>
          <div className=''>✓</div>
          <div className='col-span-7'>Chat & Phone Support(24*7)</div>
          <div className=''>✓</div>
          <div className='col-span-7'>Dedicated Worker nodes</div>
          <div className=''>✓</div>
          <div className='col-span-7'>Dedicated cost optimization review</div>
          <div className=''>✓</div>
          <div className='col-span-7'>Customized monitoring Solution</div>
        </div>
      </div>
    </div>
      <div className="bg-gray-100 p-6 rounded-lg shadow mb-28">
        <table className="w-full border-collapse">
          <thead>
            <tr>
                <h1 className='text-4xl font-semibold ml-4'>Feature</h1>
            </tr>
            <tr>
              <th className="py-2 px-4 border-b"></th>
              <th className="py-2 px-4 border-b">Silver</th>
              <th className="py-2 px-4 border-b">Gold</th>
              <th className="py-2 px-4 border-b">Platinum</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="py-2 px-4 border-b">RBAC</td>
              <td className="py-2 px-4 border-b text-center">✗</td>
              <td className="py-2 px-4 border-b text-center">✗</td>
              <td className="py-2 px-4 border-b text-center">✓</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">SSO</td>
              <td className="py-2 px-4 border-b text-center">✗</td>
              <td className="py-2 px-4 border-b text-center">✓</td>
              <td className="py-2 px-4 border-b text-center">✓</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Dedicated Support & SLA</td>
              <td className="py-2 px-4 border-b text-center">✗</td>
              <td className="py-2 px-4 border-b text-center">✓</td>
              <td className="py-2 px-4 border-b text-center">✓</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Dedicated Cost optimization review</td>
              <td className="py-2 px-4 border-b text-center">✗</td>
              <td className="py-2 px-4 border-b text-center">✓</td>
              <td className="py-2 px-4 border-b text-center">✓</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Customized monitoring solutions</td>
              <td className="py-2 px-4 border-b text-center">✗</td>
              <td className="py-2 px-4 border-b text-center">✓</td>
              <td className="py-2 px-4 border-b text-center">✓</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Custom templates & Integrations</td>
              <td className="py-2 px-4 border-b text-center">✗</td>
              <td className="py-2 px-4 border-b text-center">✓</td>
              <td className="py-2 px-4 border-b text-center">✓</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Deploy to one cloud (AWS,GCP)</td>
              <td className="py-2 px-4 border-b text-center">✗</td>
              <td className="py-2 px-4 border-b text-center">✗</td>
              <td className="py-2 px-4 border-b text-center">✓</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Production ready templates</td>
              <td className="py-2 px-4 border-b text-center">✗</td>
              <td className="py-2 px-4 border-b text-center">✗</td>
              <td className="py-2 px-4 border-b text-center">✓</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Cost Explorer</td>
              <td className="py-2 px-4 border-b text-center">✗</td>
              <td className="py-2 px-4 border-b text-center">✗</td>
              <td className="py-2 px-4 border-b text-center">✓</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Slack Integration</td>
              <td className="py-2 px-4 border-b text-center">✗</td>
              <td className="py-2 px-4 border-b text-center">✓</td>
              <td className="py-2 px-4 border-b text-center">✓</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Defaults alerts & Incidents</td>
              <td className="py-2 px-4 border-b text-center">✗</td>
              <td className="py-2 px-4 border-b text-center">✓</td>
              <td className="py-2 px-4 border-b text-center">✓</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Multi-Cloud Deployment</td>
              <td className="py-2 px-4 border-b text-center">✗</td>
              <td className="py-2 px-4 border-b text-center">✗</td>
              <td className="py-2 px-4 border-b text-center">✓</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Autoscaling</td>
              <td className="py-2 px-4 border-b text-center">✗</td>
              <td className="py-2 px-4 border-b text-center">✓</td>
              <td className="py-2 px-4 border-b text-center">✓</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="bg-gray-100 p-6 rounded-lg shadow mb-28">
        <table className="w-full border-collapse">
          <thead>
            <tr>
                <h1 className='text-4xl font-semibold ml-4'>Usage</h1>
            </tr>
            <tr>
              <th className="py-2 px-4 border-b"></th>
              <th className="py-2 px-4 border-b">Silver</th>
              <th className="py-2 px-4 border-b">Gold</th>
              <th className="py-2 px-4 border-b">Platinum</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="py-2 px-4 border-b">Deployment/Month</td>
              <td className="py-2 px-4 border-b text-center">10</td>
              <td className="py-2 px-4 border-b text-center">50</td>
              <td className="py-2 px-4 border-b text-center">Unlimited</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Repositories</td>
              <td className="py-2 px-4 border-b text-center">5</td>
              <td className="py-2 px-4 border-b text-center">10</td>
              <td className="py-2 px-4 border-b text-center">Unlimited</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Projects</td>
              <td className="py-2 px-4 border-b text-center">5</td>
              <td className="py-2 px-4 border-b text-center">10</td>
              <td className="py-2 px-4 border-b text-center">Unlimited</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Build minutes/month</td>
              <td className="py-2 px-4 border-b text-center">500</td>
              <td className="py-2 px-4 border-b text-center">1000</td>
              <td className="py-2 px-4 border-b text-center">Unlimited</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Environments</td>
              <td className="py-2 px-4 border-b text-center">16</td>
              <td className="py-2 px-4 border-b text-center">50</td>
              <td className="py-2 px-4 border-b text-center">Unlimited</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Apps Deployed</td>
              <td className="py-2 px-4 border-b text-center">10</td>
              <td className="py-2 px-4 border-b text-center">20</td>
              <td className="py-2 px-4 border-b text-center">Unlimited</td>
            </tr>            
          </tbody>
        </table>
      </div>
      <div className="bg-gray-100 p-6 rounded-lg shadow mb-28">
        <table className="w-full border-collapse">
          <thead>
            <tr>
                <h1 className='text-4xl font-semibold ml-4'>Monitor</h1>
            </tr>
            <tr>
              <th className="py-2 px-4 border-b"></th>
              <th className="py-2 px-4 border-b">Silver</th>
              <th className="py-2 px-4 border-b">Gold</th>
              <th className="py-2 px-4 border-b">Platinum</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="py-2 px-4 border-b">Logs & Metric retention</td>
              <td className="py-2 px-4 border-b text-center">6 months</td>
              <td className="py-2 px-4 border-b text-center">6 months</td>
              <td className="py-2 px-4 border-b text-center">1 yr</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Standard metrics</td>
              <td className="py-2 px-4 border-b text-center">✓</td>
              <td className="py-2 px-4 border-b text-center">✓</td>
              <td className="py-2 px-4 border-b text-center">✓</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Alerts & Incidents</td>
              <td className="py-2 px-4 border-b text-center">✓</td>
              <td className="py-2 px-4 border-b text-center">✓</td>
              <td className="py-2 px-4 border-b text-center">✓</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Real-Time alerts</td>
              <td className="py-2 px-4 border-b text-center">✗</td>
              <td className="py-2 px-4 border-b text-center">✓</td>
              <td className="py-2 px-4 border-b text-center">✓</td>
            </tr>           
          </tbody>
        </table>
      </div>
      <div className="bg-gray-100 p-6 rounded-lg shadow mb-28">
        <table className="w-full border-collapse">
          <thead>
            <tr>
                <h1 className='text-4xl font-semibold ml-4'>Infrastructure</h1>
            </tr>
            <tr>
              <th className="py-2 px-4 border-b"></th>
              <th className="py-2 px-4 border-b">Silver</th>
              <th className="py-2 px-4 border-b">Gold</th>
              <th className="py-2 px-4 border-b">Platinum</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="py-2 px-4 border-b">VPC</td>
              <td className="py-2 px-4 border-b text-center">✗</td>
              <td className="py-2 px-4 border-b text-center">✗</td>
              <td className="py-2 px-4 border-b text-center">✓</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">EKS Worker Nodes</td>
              <td className="py-2 px-4 border-b text-center">✗</td>
              <td className="py-2 px-4 border-b text-center">✗</td>
              <td className="py-2 px-4 border-b text-center">✓</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">EKS CLuster</td>
              <td className="py-2 px-4 border-b text-center">✗</td>
              <td className="py-2 px-4 border-b text-center">✗</td>
              <td className="py-2 px-4 border-b text-center">✓</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Dedicated Infrastructure</td>
              <td className="py-2 px-4 border-b text-center">✗</td>
              <td className="py-2 px-4 border-b text-center">✗</td>
              <td className="py-2 px-4 border-b text-center">✓</td>
            </tr>           
          </tbody>
        </table>
      </div>
      <div className="bg-gray-100 p-6 rounded-lg shadow mb-28">
        <table className="w-full border-collapse">
          <thead>
            <tr>
                <h1 className='text-4xl font-semibold ml-4'>Support</h1>
            </tr>
            <tr>
              <th className="py-2 px-4 border-b"></th>
              <th className="py-2 px-4 border-b">Silver</th>
              <th className="py-2 px-4 border-b">Gold</th>
              <th className="py-2 px-4 border-b">Platinum</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="py-2 px-4 border-b">Email Support</td>
              <td className="py-2 px-4 border-b text-center">✗</td>
              <td className="py-2 px-4 border-b text-center">✗</td>
              <td className="py-2 px-4 border-b text-center">✓</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Chat Support</td>
              <td className="py-2 px-4 border-b text-center">✗</td>
              <td className="py-2 px-4 border-b text-center">✗</td>
              <td className="py-2 px-4 border-b text-center">✓</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Dedicated Support Channels</td>
              <td className="py-2 px-4 border-b text-center">✗</td>
              <td className="py-2 px-4 border-b text-center">✗</td>
              <td className="py-2 px-4 border-b text-center">✓</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">SLA Guarantees</td>
              <td className="py-2 px-4 border-b text-center">✗</td>
              <td className="py-2 px-4 border-b text-center">✗</td>
              <td className="py-2 px-4 border-b text-center">✓</td>
            </tr>           
          </tbody>
        </table>
      </div>
      <FAQ />
    </div>
  </div>
  )
}

export default Price
